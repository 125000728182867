<template>
  <div class="page-container page-customType">
      <el-form label-position="right"  :model="saveForm" :rules="saveRules" ref="saveForm" label-width="160px">
          <el-form-item label="标识模版编码" prop="idenModelNumber">
              <el-input v-model="saveForm.idenModelNumber" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="数据模版名称"  prop="modelId">
            <el-select v-model="saveForm.modelId" filterable @change="dataModelChange" :disabled="true">
                <el-option v-for="item in dataModuleOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        border
        tooltip-effect="dark"
        style="width: 100%;"
       >
        <el-table-column
                prop="nameCh"
                align="center"
                label="中文名称"
        >
        </el-table-column>
        <el-table-column
                prop="minLength"
                align="center"
                label="最小长度"
        >
        </el-table-column>
        <el-table-column
                prop="maxLength"
                align="center"
                label="最大长度"
        >
        </el-table-column>
        <el-table-column
            prop="value"
            align="center"
            label="值"
            width="450px"
        >
        </el-table-column>

      </el-table>
      <div class="saveBox">
          <el-button type="primary" @click="goBack">返回</el-button>
      </div>
  </div>
</template>
<script>
import {  fetchDataModelList, fetchDataModelInfo, fetchAddIdenModell, fetchIdenModelInfo } from '@/api/customPage.js';
export default {
    data() {
        return {
          modelId: '',
          saveForm:{
            idenModelNumber: '',
            modelId: '',
          },
          saveRules: {
            idenModelNumber: [
              { required: true, message: '请输入标识模版编码', trigger: 'blur' },
            ],
            modelId: [
              { required: true, message: '请选择数据模版名称', trigger: ['blur','change'] },
            ],
          },
          typeOptions: [
              {
                  value: 4,
                  label: '字符串'
              },
              {
                  value: 5,
                  label: '单选'
              },
              {
                  value: 6,
                  label: '日期'
              },
          ],
          dataModuleOptions: [],
          tableData: []
        };
    },
    created() {
      this.getOptionsData()
    },
    mounted() {
      this.init()
    },
    methods: {
        init() {
            // 编辑回显
            this.modelId = this.$route.query.id
            if(this.modelId) {
                this.saveForm.idenModelNumber = this.$route.query.idenModelNumber

                let params = {
                    id: this.modelId,
                }
                fetchIdenModelInfo(params).then(res => {
                    if(res.code === 200) {
                        this.tableData = res.data.dataList
                    }

                })
            }

        },
        getOptionsData() {
          let params = {
            companyId: JSON.parse(localStorage.getItem('info')).companyId,
            pageNum: 1,
            pageSize: 1000,
          }
          fetchDataModelList(params).then(res => {
            if(res.code === 200) {
              this.dataModuleOptions = res.data.list.map(item => {
                  return {
                      label: item.modelName,
                      value: item.id
                  }
              })
              if(this.$route.query.modelId) {
                //   this.saveForm.modelId = 142
                this.saveForm.modelId = JSON.parse(this.$route.query.modelId)
                // this.dataModelChange(this.saveForm.modelId)
              }
            }
          })
        },
        saveAdd() {
            // 添加或编辑
            this.$refs.saveForm.validate((valid) => {
                if(valid) {
                    console.log(this.$route.query.modeType)
                    let params={}
                    if(this.$route.query.modeType === 'add') { // 添加
                        params = {
                            companyId: JSON.parse(localStorage.getItem('info')).companyId,
                            idenModelNumber: this.saveForm.idenModelNumber,
                            modelId: this.saveForm.modelId,
                            data: JSON.stringify(this.tableData)
                        }
                    }else { // 编辑
                        params = {
                            id: this.modelId,
                            companyId: JSON.parse(localStorage.getItem('info')).companyId,
                            idenModelNumber: this.saveForm.idenModelNumber,
                            modelId: this.saveForm.modelId,
                            data: JSON.stringify(this.tableData)
                        }
                    }
                    fetchAddIdenModell(params).then(res => {
                        if(res.code === 200) {
                            this.$message({
								message: "保存成功",
								type: "success",
							});
                            this.$router.push({path: "/configHandleType"});
                        }else {
                           this.$message({
								message: res.message,
								type: "error",
							});
                        }
                    })
                }
            })
        },
        dataModelChange(val) {
            let params = {
                    dataModelId: val,
                }
            fetchDataModelInfo(params).then(res => {
                if(res.code === 200) {
                    this.tableData = res.data.map(item => {
                        return { ...item, value: ''}
                    })
                    console.log('-----this.tableData---', this.tableData)
                }
            })
        },
        goBack() {
            this.$router.back()
        }
    }
}
</script>
<style scoped lang="scss">
.page-container{
  background: #fff;
  padding: 10px;
}
.operations{
            display: flex;
            justify-content: space-between;
            height: 60px;
            align-items: center;
            .operation-btns{
                display: flex;
                div{
                    border-radius: 4px;
                    // background: #B9EBEA;
                    background: #51CDCB;
                    font-size: 14px;
                    line-height: 30px;
                    height: 30px;
                    color: #fff;
                    padding: 0 20px;
                    margin-right: 10px;
                    cursor: pointer;
                }
                .disabled{
                    background: #B9EBEA;
                }
            }
            .search-user{
                display: flex;
                .search-input{
                    height: 30px;
                    margin-right: 10px;
                    .el-input__inner{
                        height: 30px;
                    }
                }
                .search-btn{
                    border-radius: 4px;
                    // background: #B9EBEA;
                    background: #51CDCB;
                    font-size: 14px;
                    line-height: 30px;
                    height: 30px;
                    color: #fff;
                    padding: 0 20px;
                    cursor: pointer;
                }
                .disabled{
                    background: #B9EBEA;
                }
            }
        }

        .saveBox{
            text-align: center;
            padding-top: 20px;
            padding-bottom: 10px;
        }

/deep/ .el-input.is-disabled .el-input__inner {
    background-color: #fff;
    color: #767a82;
}

</style>
